<script>
import {
    Component,
    Vue,
    Prop
} from 'vue-property-decorator';

@Component
export default class UploadFiles extends Vue {
    drag = false;

    @Prop({
        type: File | null,
        default: function () {
            return null;
        },
    })
    filelist;

    onChange() {
        // const newFileList = Array.from(this.$refs.file.files);
        const newFileList = this.$refs.file.files[0];
        this.$emit("update:filelist", newFileList);
    }

    drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); 
        this.drag=false;
    }
}
</script>

<template>
<!-- <UploadFiles :filelist.sync="filelist" /> -->
<div class="container-files" :class="{drag: drag}">
    <div v-cloak @drop.prevent="drop" @dragover.prevent @dragover="drag=true" @dragleave="drag=false"> <!-- @drop="drop"-->
        <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="input-file" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />

        <div class="upload form-element">
            <h5>{{$t("upload-files.upload-files-part-one")}}</h5>
            <h4>
                <span>{{$t("upload-files.upload-files-part-two")}}</span>
                <label for="assetsFieldHandle">
                    <span style="color: #3884e7">{{" "+$t("upload-files.upload-files-browse")+" "}}</span>
                </label>
                <span>{{$t("upload-files.upload-files-part-three")}}</span>
            </h4>
        </div>
    </div>
    <img :src="require('@/assets/document.png')" />
</div>
</template>

<style lang="scss" scoped>
*{
   margin: 0;
   padding: 0;
}

img{
    height: 3.2rem;
}

.drag{
    outline: 2px dashed #80808063;
}

.container-files {
    padding: .1rem 1.2rem .1rem .3rem;
    border-radius: .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload {
    display: block;
    padding: 1.5rem;
    margin-top: 1rem;
    text-align: left;
    color: #000000;

    .certificate {
        width: 3rem;
        position: absolute;
        transform: scaleX(-1) translate(100%, -70%);
    }

    h5 {
        margin-bottom: .5rem;
    }

    h4{
        color: rgba(0, 0, 0, 0.593);
    }

    h4,
    h5 {
        font-weight: normal;
    }
}

label{
    cursor: pointer;
}

.input-file {
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

.form-element {
    flex-direction: column;
    min-width: 250px;
    box-sizing: border-box;
    text-align: left;
    padding: 1rem;
    margin: 0 auto;
}
</style>
