
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "@/interfaces/vue";
import UploadFiles from "@/components/UploadFiles.vue";
import { Therapist } from "@/interfaces";
import { Route } from "vue-router";
import { get, patch } from "@/api";
import i18n from "@/i18n";
import { TherapistInfo } from "@/interfaces/therapist";
const pageKey = "background";

Component.registerHooks(["beforeRouteLeave"]);

interface Specialization {
  id: number;
  specialization: string;
}

@Component({
  components: {
    UploadFiles,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class InfoBackgroundComponent extends Vue {
  filelist: File | null = null;
  oldFile: File | null = null;
  fileUrl: string | null = null;
  isDataFilledCorrect = true;
  chosenProf: Specialization | null = null;
  specializations: Specialization[] | null = null;
  schools: string[] = [""];
  courses: string[] = [""];
  pwz_id: number | null = null;

  @Prop({
    default: function() {
      return null;
    },
  })
  therapist!: Therapist.TherapistInfo | null;

  @Prop()
  firstTime!: boolean;

  created() {
    this.getTherapistData();
    this.getSpecializations();
  }

  async getSpecializations() {
    try {
      const { data: dictionaries } = await get.dict();
      if (dictionaries)
        this.specializations = [...dictionaries["specialization_ids"]];
      if (this.specializations)
        this.chosenProf = this.specializations.filter(
          (e: any) => this.therapist?.specializations.indexOf(e.id) !== -1
        )[0];
    } catch (err) {
      console.log(err);
    }
  }

  async getTherapistData() {
    if (this.therapist) {
      if (this.therapist.schools.length !== 0)
        this.schools = [...this.therapist.schools];
      if (this.therapist.courses.length !== 0)
        this.courses = [...this.therapist.courses];
      this.pwz_id = this.therapist.pwz_id;
      if (this.therapist.certification) {
        try {
          const { data: certification } = await get.therapistPhoto(
            this.therapist.certification
          );
          this.filelist = new File(
            [certification],
            this.therapist.certification.split("/")[
              this.therapist.certification.split("/").length - 1
            ],
            { type: certification.type }
          );
          this.oldFile = this.filelist;
          this.fileUrl = this.therapist.certification;
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  get isDataFilled() {
    const isSchoolsEmpty =
      this.schools.filter((school) => school.toString().trim() === "")
        .length !== 0;
    const isCoursesEmpty =
      this.courses.filter((course) => course.toString().trim() === "")
        .length !== 0;
    return (
      this.chosenProf &&
      !isSchoolsEmpty &&
      ((isCoursesEmpty && this.therapist?.courses === undefined) ||
        (!isCoursesEmpty && this.therapist?.courses !== undefined))
    );
  }

  cancel() {
    this.filelist = null;
    this.isDataFilledCorrect = true;
    this.chosenProf = null;
    this.specializations = null;
    this.schools = [""];
    this.courses = [""];
    this.pwz_id = null;
    this.getTherapistData();
    this.getSpecializations();
  }

  getIsCheckedImage(prof: Specialization) {
    if (this.chosenProf === prof) {
      return require(`@/assets/checked.png`);
    } else {
      return require(`@/assets/unchecked.png`);
    }
  }

  addCourse() {
    this.courses.push("");
  }

  addSchool() {
    this.schools.push("");
  }

  removeCertificate() {
    if (this.filelist) {
      this.filelist = null;
      this.fileUrl = null;
    }
  }

  removeCourse(index: number) {
    if (this.courses.length !== 1) {
      this.courses.splice(index, 1);
    } else {
      this.courses.splice(index, 1, "");
    }
  }

  removeSchool(index: number) {
    if (this.schools.length !== 1) {
      this.schools.splice(index, 1);
    } else {
      this.schools.splice(index, 1, "");
    }
  }

  get whatWasChanged() {
    const changedData: Record<
      string,
      string | string[] | null | number | number[] | File
    > = {};
    if (this.therapist) {
      if (this.chosenProf) {
        const newChosenProf = [];
        newChosenProf.push(this.chosenProf.id);
        if (this.therapist.specializations[0] !== this.chosenProf.id)
          changedData["specializations"] = [...newChosenProf];
      }
      if (this.therapist.pwz_id !== this.pwz_id)
        changedData["pwz_id"] = this.pwz_id;
      if (
        this.therapist.schools.sort().toString() !==
        this.schools.sort().toString()
      )
        changedData["schools"] = [...this.schools];
      if (
        this.therapist.courses.sort().toString() !==
        this.courses.sort().toString()
      )
        changedData["courses"] = [...this.courses];
      if (this.filelist?.name !== this.oldFile?.name)
        changedData["certification"] = this.filelist;
    }
    return changedData;
  }

  assignNewData(therapist: TherapistInfo) {
    if (therapist) {
      if (therapist.schools.length !== 0) this.schools = [...therapist.schools];
      if (therapist.courses.length !== 0) this.courses = [...therapist.courses];
      this.pwz_id = therapist.pwz_id;
      if (therapist.certification) {
        this.oldFile = this.filelist;
        this.fileUrl = therapist.certification;
      }
    }
  }

  async submit() {
    const keys = Object.keys(this.whatWasChanged);
    if (keys.length !== 0) {
      try {
        const { data: therapist } = await patch.therapistInfo(
          this.whatWasChanged
        );
        this.$emit("update:therapist", therapist);
        if (this.firstTime)
          this.$store.commit("setStage", therapist.completed_fillings);
        else this.$openModal({ component: "SavedSuccessfullyModal" });
        this.assignNewData(therapist);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async save() {
    if (this.isDataFilled) {
      await this.submit();
    } else {
      this.isDataFilledCorrect = false;
    }
  }

  async beforeRouteLeave(to: Route, from: Route, next: any) {
    if (
      this.firstTime &&
      to.meta.requiresAuth &&
      to.path !== "/dashboard/myprofile/personal"
    ) {
      if (this.isDataFilled) {
        await this.submit();
        next();
      }
      this.isDataFilledCorrect = false;
    } else if (
      (this.firstTime &&
        Object.keys(this.whatWasChanged).length === 0 &&
        to.path === "/dashboard/myprofile/personal") ||
      (!this.firstTime && Object.keys(this.whatWasChanged).length === 0) ||
      !this.$store.state.user
    ) {
      next();
    } else {
      this.$openModal({
        component: "InfoFillingModal",
        props: {
          next: () => next(),
          save: () => this.save(),
          cancel: () => this.cancel(),
          isDataFilled: this.isDataFilled,
        },
        this: this,
      });
    }
  }

  @Watch("filelist")
  onFileChange() {
    if (this.filelist) {
      this.fileUrl = URL.createObjectURL(this.filelist);
    }
  }
}
